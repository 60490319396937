import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CardModule, ButtonModule],
  selector: 'nvx-forbidden-no-admin',
  standalone: true,
  templateUrl: './forbidden-no-admin.component.html',
})
export class ForbiddenNoAdminComponent {
  authService = inject(MsalService);

  logout = () => {
    this.authService.logoutRedirect();
  };
}
