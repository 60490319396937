<div class="flex h-full items-center justify-center">
  <p-card class="w-96" header="Forbidden" subheader="Somethings wrong here.">
    <ng-template pTemplate="header">
      <img alt="Card" ngSrc="assets/images/warning.svg" />
    </ng-template>
    <p>
      It looks like you are missing the admin role. If you believe you should not see this message, and that you should
      be able to access the NVX Admin Console, please reach out to susana.wong&#64;neelevat.com.
    </p>
    <ng-template pTemplate="footer">
      <div class="mt-1 flex gap-3">
        <p-button class="w-full" label="Leave Admin Console" styleClass="w-full" (onClick)="logout()" />
      </div>
    </ng-template>
  </p-card>
</div>
